export const SelfeRouteConst = {
  HOME: '/',
  START_PAGE: '/',
  FRAME: '/frame',
  LAYOUT: '/layout',
  THEME: '/theme',
  PAYMENT: '/payment',
  SHOOTING_POSITION: '/shooting-position',
  SHOOTING_MODE: '/shooting-mode',
  SHOOTING: '/shooting',
  PICK_PHOTO: '/pick-photo',
  ADD_FILTER: '/add-filter',
  PRINT: '/print',
  PHOTO_GUIDE: '/photo-guide',
  PHOTO_GUIDE_2: '/photo-guide-2',
  GUIDE: '/guide',
  GUIDE_API: '/guide-api',
  STICKER: '/sticker',
};

export const PrintingPhotoRouteConst = {
  HOME: '/pp',
  START_PAGE: '/pp',
  PAYMENT: '/pp/payment',
  QR_CODE: '/pp/qr-code',
  PRINT: '/pp/print',
  FE_COULD_PRINTING: '/upload-printing-photo',
};

export const QUERY_STRING = {
  TRANSACTION: 't',
  CAM_DEVICE: 'use_cam',
  FE_CLOUD: {
    TRANSACTION: 'cld_t',
    BOOTH_ID: 'cld_bid',
  },
};
