import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { filter, map, range, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { usePageTimer } from 'hooks/usePageTimer';
import { UseCarouselTypes } from 'hooks/useCarousel';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import {
  INIT_STICKER_CATEGORY_ID,
  NUMBER_OF_THEME_CATEGORY_PER_PAGE,
  NUMBER_OF_THEME_PER_PAGE,
  SHOOTING_TYPES,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { Carousel } from 'components/carousel/Carousel';
import TimerText from 'components/timer-text/TimerText';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import Button from 'components/button/Button';
import PageActions from 'components/page-actions/PageActions';
import { processedImageUrl, resourceUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';
import './select-sticker.css';

function SelectSticker() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const {
    listStickerCategoryPhotos,
    themeDetail,
    currentLayout,
    listStickerPhotos,
  } = useBoothAppContext();
  const {
    themeId,
    transactionId,
    pickedPhotos,
    stickerCategoryId,
    listSelectedSticker,
    shootingModeId,
  } = useAppSelector((state) => state.selfeApp);
  const refTarget = useRef<HTMLInputElement | null>(null);
  const isHasVideo =
    !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN);
  const { second } = usePageTimer();
  const [stickerCarosel, setStickerCarousel] =
    useState<UseCarouselTypes | null>(null);

  const handleSelectSticker = (id: number, link: string) => () => {
    audio?.playClickToSelect?.();
    const NumberIdSimilar = map(
      filter(listSelectedSticker, (e) => e.stickerId === id),
      (e) => e.stickerId,
    );
    const newSticker = [
      ...listSelectedSticker,
      {
        stickerId: id,
        idUnique:
          listSelectedSticker.findIndex((e: any) => e.stickerId === id) !== -1
            ? `${id}-${NumberIdSimilar.length}`
            : id.toString(),
        image: link,
        width: 100,
        height: 100,
        rotate: 0,
        translate: [
          (refTarget?.current?.clientWidth || 0) / 2 - 50,
          (refTarget?.current?.clientHeight || 0) / 2 - 50,
        ],
      },
    ];
    dispatch(setSelfeAppStateAction({ listSelectedSticker: newSticker }));
  };

  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  const handleNext = async () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };
  const handleSelectCategory = (category: number | undefined) => () => {
    if (category) {
      audio?.playClickToSelect?.();
      stickerCarosel?.setActive?.(0);
      dispatch(setSelfeAppStateAction({ stickerCategoryId: category }));
    }
  };

  useEffect(() => {
    !stickerCategoryId &&
      dispatch(
        setSelfeAppStateAction({
          stickerCategoryId:
            listStickerCategoryPhotos?.data?.[0]?.id ||
            INIT_STICKER_CATEGORY_ID,
        }),
      );
  }, [dispatch, listStickerCategoryPhotos, stickerCategoryId]);

  return (
    <>
      <div className="theme-page">
        <TimerText second={second} />
        <div className="page-title-margin theme-title">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:selectSticker`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:selectSticker`)}
          </Typography>
        </div>
        <div className="text-center category-button-group">
          <Carousel
            hiddenDot
            hiddenBtn={false}
            slides={map(
              range(
                1,
                Math.ceil(
                  (listStickerCategoryPhotos?.totalRecord || 0) /
                    NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                ) + 1,
              ),
              (_, ind) => (
                <div className="button-group">
                  {map(
                    slice(
                      listStickerCategoryPhotos?.data,
                      ind * NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                      (ind + 1) * NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                    ),
                    (category) => {
                      const isCateActive = isEqualVal(
                        stickerCategoryId || INIT_STICKER_CATEGORY_ID,
                        category?.id,
                      );
                      return (
                        <Button
                          key={category?.id}
                          color={isCateActive ? 'primary' : 'transparent'}
                          onClick={handleSelectCategory(category?.id)}
                          className={cx('category-button', {
                            'category-button-active': isCateActive,
                          })}
                          TypographyProps={{
                            className: 'text-ellipsis overflow-hidden',
                          }}
                        >
                          {category?.name}
                        </Button>
                      );
                    },
                  )}
                </div>
              ),
            )}
          />
        </div>
        <div className="">
          <div className="flex items-center justify-center theme-wrapper">
            <div className="theme-image-wrapper flex flex-col">
              <PhotoSheet
                className="theme-image-wrapper-example-img"
                forwardedRef={refTarget}
                isSelectSticker
                data={{
                  ...currentLayout,
                  pictures: map(
                    currentLayout?.pictures,
                    (item, ind: number) => ({
                      ...item,
                      image: pickedPhotos?.[ind]
                        ? processedImageUrl(
                            transactionId,
                            pickedPhotos?.[ind]?.fileName,
                          )
                        : '',
                      rotate: pickedPhotos?.[ind]?.rotate,
                      flip: pickedPhotos?.[ind]?.flip,
                    }),
                  ),
                  image: themeDetail?.image || currentLayout?.image,
                }}
              />
              {isHasVideo && (
                <Typography
                  className="font-semibold mt-7"
                  variant={TYPOGRAPHY_VARIANTS.SMALL}
                  firstCapCase
                >
                  {t('page:warningSticker')}
                </Typography>
              )}
            </div>
            <div className="slide-wrapper">
              <Carousel
                setCarousel={setStickerCarousel}
                className="slide-carousel"
                slides={map(
                  range(
                    1,
                    Math.ceil(
                      (listStickerPhotos?.totalRecord || 0) /
                        NUMBER_OF_THEME_PER_PAGE,
                    ) + 1,
                  ),
                  (_, ind) => (
                    <div className="theme-group">
                      {map(
                        slice(
                          listStickerPhotos?.data,
                          ind * NUMBER_OF_THEME_PER_PAGE,
                          (ind + 1) * NUMBER_OF_THEME_PER_PAGE,
                        ),
                        (item) => (
                          <button
                            key={item?.id}
                            type="button"
                            className={cx('sticker-item')}
                            onClick={handleSelectSticker(
                              item?.id,
                              item?.imageUrl,
                            )}
                          >
                            <img
                              src={resourceUrl(item?.imageUrl)}
                              alt="theme-img"
                            />
                          </button>
                        ),
                      )}
                    </div>
                  ),
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <PageActions
        handleBack={handleBack}
        handleContinue={handleNext}
        NextButtonProps={{ disabled: !themeId }}
      />
    </>
  );
}

export default SelectSticker;
