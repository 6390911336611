import React, { useEffect } from 'react';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { usePrintPhotoApiActionMutation } from 'store/api/print.slice-api';
import { useAppSelector } from 'store/store-hooks';
import { FRAME_TO_PAYMENT_DIRECTLY } from 'constants/photo.const';
import { emitSeqLog } from 'functions/seq-logging.func';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useNavigate } from 'react-router-dom';
import { isEqualVal } from 'helpers/string.helper';
import { usePageTimer } from 'hooks/usePageTimer';
import { useCustomizeUI } from 'hooks/useCustomizeUI';
import './print.css';
import './print.customize-ui.css';

function Print() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { printUI } = useCustomizeUI();
  const { frameId, layoutId, transactionId, quantitySheet } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { getStartNodePath } = useNewPhotoLifeCycleStep();
  const [printPhotoApiAction] = usePrintPhotoApiActionMutation();
  usePageTimer({
    forwardFunc: () => handleRedirectToStart(),
    isNotShowConfirm: true,
  });

  // Redirect back lại bắt đầu
  const handleRedirectToStart = () => {
    emitSeqLog?.({ messageTemplate: `[Print] End transaction!` });
    navigate(getStartNodePath() || '');
  };

  useEffect(() => {
    const printNumber = isEqualVal(FRAME_TO_PAYMENT_DIRECTLY, frameId)
      ? Math.max(quantitySheet / 2, 1)
      : quantitySheet;
    printPhotoApiAction({
      transactionId,
      numberOfImage: printNumber,
      layoutId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="start-page-title-container">
      <div className="start-page-title-wrapper text-center whitespace-pre-line print-title-wrapper">
        <Typography
          variant={TYPOGRAPHY_VARIANTS.H1}
          className="start-page-title"
          data-text={t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
        >
          {t(`${I18nNamespace.COMMON}:pleaseWaitForPrint`)}
        </Typography>
      </div>
      {printUI.isShowMacos && (
        <div className="start-page-image-wrapper">
          <img src={printUI.macosImg} alt="macos" />
        </div>
      )}
    </div>
  );
}

export default Print;
