/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useSoundContext } from 'context/SoundContext';
import { emitSeqLog } from 'functions/seq-logging.func';
import { useCustomizeUI } from 'hooks/useCustomizeUI';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { camerasApi } from 'store/api/camera.slice-api';
import {
  resetSelfeAppStateAction,
  setSelfeAppStateAction,
} from 'store/features/app/selfeAppSlice';
import {
  resetTrackingStateAction,
  setTrackingStateAction,
} from 'store/features/app/trackingSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { v4 as uuidv4 } from 'uuid';
import './start-page.css';
import './start-page.customize-ui.css';

function StartPage() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const { audio } = useSoundContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { startPageUI } = useCustomizeUI();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { isPaidButNotShooting } = useAppSelector((s) => s.selfeApp);

  const [isClickedOutsizeBtnStart, setIsClickedOutsizeBtnStart] =
    useState(false);

  const handleStart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const transactionId = uuidv4();
    audio?.playContinueClick?.();
    dispatch(setSelfeAppStateAction({ transactionId }));
    dispatch(setTrackingStateAction({ LastStartTimePurchase: new Date() }));
    navigate(getNextPath() || '');
    emitSeqLog?.({
      messageTemplate: `[StartPage] Start with transId: ${transactionId}`,
    });
  };

  const handleOutsideBtnStart = () => {
    setIsClickedOutsizeBtnStart(true);
    setTimeout(() => setIsClickedOutsizeBtnStart(false), 1000);
  };

  useEffect(() => {
    if (!isPaidButNotShooting) {
      dispatch(resetSelfeAppStateAction());
    }
    dispatch(resetTrackingStateAction());
    dispatch(camerasApi.util.invalidateTags([{ type: 'Camera' }]));
  }, [dispatch, isPaidButNotShooting]);

  return (
    <>
      <div
        className="start-page-title-container"
        onClick={handleOutsideBtnStart}
      >
        <div className="start-page-title-wrapper text-center">
          <button type="button" onClick={handleStart}>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              className={cx('start-page-title', {
                'text-stroke-swap': isClickedOutsizeBtnStart,
              })}
              data-text={t(`${I18nNamespace.COMMON}:touchToStart`)}
            >
              {t(`${I18nNamespace.COMMON}:touchToStart`)}
            </Typography>
          </button>
        </div>
        {startPageUI.isShowMacos && (
          <div className="start-page-image-wrapper">
            <img src={startPageUI.macosImg} alt="macos" />
          </div>
        )}
      </div>
    </>
  );
}

export default StartPage;
