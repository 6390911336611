import React, { FC, HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import { TYPOGRAPHY_COMPONENT, TYPOGRAPHY_VARIANTS } from './typography-utils';
import './typography.css';
import './typography.customize-ui.css';

export enum TypographyColor {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WHITE = 'white',
  WARNING = 'warning',
}

export type Props = HtmlHTMLAttributes<HTMLParagraphElement> & {
  variant?: string;
  component?: string;
  normalCase?: boolean;
  upperCase?: boolean;
  firstCapCase?: boolean;
  color?: TypographyColor;
};

const Typography: FC<Props> = ({
  className,
  variant = TYPOGRAPHY_VARIANTS.BODY,
  normalCase = false,
  upperCase = false,
  firstCapCase = false,
  color = TypographyColor.WHITE,
  ...rest
}) => (
  <DynamicComponent
    {...rest}
    className={cx(
      'pb-typography font-Nunito',
      {
        'normal-case': normalCase,
        uppercase: upperCase,
        'pb-typography-firstCapCase': firstCapCase,
        'text-pb-h1': variant === TYPOGRAPHY_VARIANTS.H1,
        'text-pb-h2': variant === TYPOGRAPHY_VARIANTS.H2,
        'text-pb-body': variant === TYPOGRAPHY_VARIANTS.BODY,
        'text-pb-small': variant === TYPOGRAPHY_VARIANTS.SMALL,
        'text-pb-xs': variant === TYPOGRAPHY_VARIANTS.XS,

        'text-secondary-text-color': color === TypographyColor.DEFAULT,
        'text-white': color === TypographyColor.WHITE,
        'pb-typography-primary-color': color === TypographyColor.PRIMARY,
        'pb-typography-secondary-color': color === TypographyColor.SECONDARY,
        'pb-typography-warning-color': color === TypographyColor.WARNING,
      },
      className,
    )}
  />
);

const DynamicComponent: FC<Props> = ({
  component = TYPOGRAPHY_COMPONENT.DIV,
  children,
  ...rest
}) => React.createElement(component, rest, children);

export default Typography;
