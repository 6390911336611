/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react';
import Moveable from 'react-moveable';
import { map } from 'lodash';
import { Icons } from '../../assets/icon/Icons';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { setSelfeAppStateAction } from '../../store/features/app/selfeAppSlice';
import { resourceUrl } from '../../helpers/url.helper';

type StickerProps = {
  isSelectSticker?: boolean;
  [x: string]: any;
};

const Sticker: FC<StickerProps> = ({ isSelectSticker = false, ...rest }) => {
  const refTarget = useRef<any>(null);
  const [target, setTarget] = useState<any>();
  const [dragTarget, setDragTarget] = useState<any>();
  const dispatch = useAppDispatch();
  const { listSelectedSticker } = useAppSelector((state) => state.selfeApp);
  const [frameId, setFrameId] = useState('');
  const [targetFrames, setTargetFrames] = useState<any>({});
  const [previousLength, setPreviousLength] = useState(0);
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  useEffect(() => {
    // Kiểm tra nếu độ dài mảng hiện tại lớn hơn độ dài trước đó
    if (listSelectedSticker.length > previousLength) {
      const targetID =
        listSelectedSticker[listSelectedSticker.length - 1].idUnique;
      const targetObj = listSelectedSticker.find(
        (obj) => obj.idUnique === targetID,
      );
      const newObj = {
        ...targetFrames,
        [`${targetID}`]: {
          width: targetObj?.width ? targetObj.width : 0,
          height: targetObj?.height ? targetObj.height : 0,
          rotate: targetObj?.rotate ? targetObj.rotate : 0,
          translate: [targetObj?.translate[0], targetObj?.translate[1]],
        },
      };
      setTargetFrames(newObj);
      setFrameId(targetID);
      setTarget(document.querySelector(`[data-target="${targetID}"]`));
    }
    // Cập nhật độ dài trước đó với độ dài hiện tại
    setPreviousLength(listSelectedSticker.length);
  }, [listSelectedSticker]);
  useEffect(() => {
    if (listSelectedSticker?.length === 0) {
      setFrameId('');
    }
  }, [listSelectedSticker?.length]);
  useEffect(() => {
    (async () => {
      const newSticker = [...listSelectedSticker].map((obj) => {
        if (obj.idUnique === frameId) {
          return {
            ...obj,
            width: targetFrames[frameId].width,
            height: targetFrames[frameId].height,
            rotate: targetFrames[frameId].rotate,
            translate: targetFrames[frameId].translate,
          };
        }
        return obj;
      });
      await dispatch(
        setSelfeAppStateAction({ listSelectedSticker: newSticker }),
      );
    })();
  }, [targetFrames]);
  const customAble = {
    name: 'tooltool',
    render(moveable: { state: { renderPoses: any } }) {
      const { renderPoses } = moveable.state;

      return (
        <button
          type="button"
          className="bg-red-500 w-8 h-8 flex justify-center items-center text-white rounded-full !z-[1000000]"
          onClick={(e) => {
            e.stopPropagation();
            const newStickers = [...listSelectedSticker].filter(
              (i) => i.idUnique !== frameId,
            );
            dispatch(
              setSelfeAppStateAction({ listSelectedSticker: newStickers }),
            );
            if (frameId) {
              const { [frameId]: deletedProp, ...newframes } = targetFrames;
              setTargetFrames(newframes);
              setTarget(undefined);
              setFrameId('');
            }
          }}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${renderPoses[1][0]}px, ${renderPoses[1][1]}px) translateZ(-50px)`,
            zIndex: 1000,
          }}
        >
          <span className="hidden">delete</span>
          <Icons.DeleteSticker />
        </button>
      );
    },
  };
  React.useEffect(
    () => setDragTarget(document.querySelector('.container')),
    [],
  );
  const getTargetId = (target: any) => target.getAttribute('data-target');
  const updateTarget = (target: any) => {
    const targetId = getTargetId(target);
    if (!targetId) {
      setTarget(undefined);
      return;
    }
    if (targetFrames[targetId]) {
      setFrameId(targetId);
      setTarget(target);
    } else {
      const targetObj = listSelectedSticker.find(
        (obj) => obj.idUnique === targetId,
      );
      const newObj = {
        ...targetFrames,
        [`${targetId}`]: {
          width: targetObj?.width ? targetObj.width : 0,
          height: targetObj?.height ? targetObj.height : 0,
          rotate: targetObj?.rotate ? targetObj.rotate : 0,
          translate: [targetObj?.translate[0], targetObj?.translate[1]],
        },
      };
      setTargetFrames(newObj);
      setFrameId(targetId);
      setTarget(target);
    }
  };

  return (
    <div
      ref={refTarget}
      {...rest}
      onTouchStart={(e) => {
        e.stopPropagation();
        if ((e.target as any).getAttribute('data-target'))
          updateTarget(e.target);
      }}
      onClick={(e) => updateTarget(e.target)}
    >
      {isSelectSticker && (
        <Moveable
          target={target}
          dragTarget={dragTarget}
          container={dragTarget}
          draggable={true}
          resizable={true}
          rotatable={true}
          scalable={false}
          tooltool={true}
          rotationPosition={'top'}
          lockAspectRatio={true}
          throttleDrag={0}
          throttleRotate={0}
          startDragRotate={0}
          throttleDragRotate={0}
          snappable={true}
          className="!z-[1000]"
          bounds={{
            left: 0,
            top: 0,
            right: refTarget?.current?.clientWidth,
            bottom: refTarget?.current?.clientHeight,
          }}
          zoom={1}
          origin={false}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          ables={[customAble]}
          onDragStart={({ set }) => {
            set(targetFrames[frameId].translate);
          }}
          onDrag={({ target, beforeTranslate }: any) => {
            const targetClone = target;
            const frames = { ...targetFrames };
            frames[frameId].translate = [...beforeTranslate];
            setTargetFrames(frames);
            targetClone.style.left = `${beforeTranslate[0]}px`;
            targetClone.style.top = `${beforeTranslate[1]}px`;
          }}
          onResizeStart={({ setOrigin, dragStart }: any) => {
            setOrigin(['%', '%']);
            setInitialWidth(targetFrames[frameId].width);
            setInitialHeight(targetFrames[frameId].height);
            dragStart && dragStart.set(targetFrames[frameId].translate);
          }}
          onResize={({ target, width, height }: any) => {
            const bounds =
              document.getElementsByClassName('moveable-guideline')[0];
            const frames = { ...targetFrames };
            frames[frameId].width = width;
            frames[frameId].height = height;
            const aspectRatio = initialWidth / initialHeight;
            const newWidth = width;
            const newHeight = newWidth / aspectRatio;

            // Giới hạn kích thước mới để không vượt quá phần bounds
            const maxWidth = refTarget.current.clientWidth;
            const maxHeight = refTarget.current.clientHeight;
            const limitedWidth = Math.min(newWidth, maxWidth);
            const limitedHeight = Math.min(newHeight, maxHeight);
            if (
              width > 40 &&
              width <
                refTarget?.current?.clientWidth -
                  frames[frameId].translate[0] &&
              height <
                refTarget?.current?.clientHeight -
                  frames[frameId].translate[1] &&
              !bounds
            ) {
              setTargetFrames(frames);
              target.style.height = `${limitedHeight}px`;
              target.style.width = `${limitedWidth}px`;
            }
          }}
          onRotateStart={({ set }) => {
            set(targetFrames[frameId].rotate);
          }}
          onRotate={({ beforeRotate, transform }) => {
            const frames = { ...targetFrames };
            frames[frameId].rotate = beforeRotate;

            setTargetFrames(frames);
            if (target) {
              (target as any).style.transform = transform;
            }
          }}
        />
      )}
      {map(listSelectedSticker, (item, index) => (
        <img
          key={item.idUnique}
          className={`target ${isSelectSticker ? 'cursor-pointer' : ''} z-10`}
          style={{
            width: item.width ? `${item.width}px` : '100px',
            height: item.height ? `${item.height}px` : 'auto',
            top: `${item.translate[1]}px`,
            left: `${item.translate[0]}px`,
            transform: `rotate(${item.rotate ? item.rotate : 0}deg)`,
          }}
          src={resourceUrl(item.image)}
          alt="abc"
          data-target={item.idUnique}
        />
      ))}
    </div>
  );
};
export default Sticker;
