import { CapturePositionModel } from 'models/photo/camera.model';
import { RobotArmPositionWithCaptureNameModel } from 'models/photo/robot-arm.model';

export function convertCapturePositionToArmPosition(
  capturePosition: CapturePositionModel,
): RobotArmPositionWithCaptureNameModel {
  return {
    capturePositionId: capturePosition?.id,
    capturePositionName: capturePosition?.name,
    point: {
      x: capturePosition?.positionX,
      y: capturePosition?.positionY,
      z: capturePosition?.positionZ,
    },
    rotation: {
      roll: capturePosition?.rotationRoll,
      pitch: capturePosition?.rotationPitch,
      yaw: capturePosition?.rotationYaw,
    },
  };
}
