import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import {
  clone,
  compact,
  filter,
  findIndex,
  includes,
  map,
  range,
  size,
} from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { PICK_PHOTO_GRID_SIZE } from 'constants/photo.const';
import { processedImageUrl } from 'helpers/url.helper';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useNavigate } from 'react-router-dom';
import PageActions from 'components/page-actions/PageActions';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import TimerText from 'components/timer-text/TimerText';
import { getAutoFillList, putToArrayHasEmptySlot } from 'helpers/array.helper';
import { isEqualVal } from 'helpers/string.helper';
import { useSoundContext } from 'context/SoundContext';
import { useBoothAppContext } from 'context/BoothAppContext';
import { usePageTimer } from 'hooks/usePageTimer';
import { setTrackingStateAction } from 'store/features/app/trackingSlice';
import './pick-photo.css';
import './pick-photo.customize-ui.css';
import { Icons } from '../../../assets/icon/Icons';
import { STRAIGHT_ANGLE } from '../../../constants/common.const';

function PickPhoto() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { listPhotoTakens, currentLayout } = useBoothAppContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const [imageHash, setImageHash] = useState(Date.now());
  const { transactionId, quantityPicOfPerSheet, pickedPhotos } = useAppSelector(
    (state) => state.selfeApp,
  );
  // timeup => auto select photo, redirect next page
  const { second } = usePageTimer({
    forwardFunc: () => {
      dispatch(
        setSelfeAppStateAction({
          pickedPhotos: getAutoFillList(
            listPhotoTakens?.data || [],
            pickedPhotos,
            quantityPicOfPerSheet,
          ),
        }),
      );
      handleNext?.();
    },
  });
  const handleRotate = (img: string) => {
    audio?.playClickToSelect?.();
    const isExist = includes(
      pickedPhotos.map((i) => i?.fileName),
      img,
    );
    const newListPick = isExist
      ? map(pickedPhotos, (i) => {
          if (isEqualVal(i?.fileName || '', img) && i) {
            return {
              fileName: i?.fileName || '',
              flip: i?.flip ? i.flip : null,
              rotate:
                (i?.rotate || i?.rotate === 0) && i?.rotate < STRAIGHT_ANGLE
                  ? i?.rotate + STRAIGHT_ANGLE
                  : 0,
            };
          }
          return i;
        })
      : clone(pickedPhotos);
    dispatch(setSelfeAppStateAction({ pickedPhotos: clone(newListPick) }));
  };
  const handleFlip = (img: string) => {
    audio?.playClickToSelect?.();
    const isExist = includes(
      pickedPhotos.map((i) => i?.fileName),
      img,
    );
    const newListPick = isExist
      ? map(pickedPhotos, (i) => {
          if (isEqualVal(i?.fileName || '', img) && i) {
            return {
              fileName: i?.fileName || '',
              rotate: i?.rotate ? i.rotate : 0,
              flip: i?.flip ? null : -1,
            };
          }
          return i;
        })
      : clone(pickedPhotos);
    dispatch(setSelfeAppStateAction({ pickedPhotos: clone(newListPick) }));
  };
  const handleClickPhoto = (img: string) => () => {
    const isExist = includes(
      pickedPhotos.map((i) => i?.fileName),
      img,
    );
    setImageHash(Date.now());
    if (isExist || size(compact(pickedPhotos)) < quantityPicOfPerSheet) {
      const newListSelected = isExist
        ? map(pickedPhotos, (i) =>
            !isEqualVal(i?.fileName || '', img) ? i : null,
          )
        : putToArrayHasEmptySlot(pickedPhotos, img);
      dispatch(
        setSelfeAppStateAction({ pickedPhotos: clone(newListSelected) }),
      );

      audio?.playClickToSelect?.();
    }
  };

  const handleRemovePhoto = (index: number) => {
    audio?.playClickToSelect?.();
    setImageHash(Date.now());
    dispatch(
      setSelfeAppStateAction({
        pickedPhotos: map(pickedPhotos, (img, i) =>
          !isEqualVal(i, index) ? img : null,
        ),
      }),
    );
  };

  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  // tracking spend time
  useEffect(() => {
    dispatch(setTrackingStateAction({ LastStartTimeEditPhoto: new Date() }));
  }, [dispatch]);
  return (
    <>
      <div>
        <TimerText second={second} />
        <div className="page-title-margin pick-photo-title">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:pickPhoto`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:pickPhoto`)}
          </Typography>
        </div>
        <div className="flex items-center justify-center pick-photo-wrapper">
          <div className="pick-photo-image-wrapper transition-img">
            <PhotoSheet
              className="pick-photo-image-wrapper-example-img"
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        pickedPhotos?.[ind]?.fileName,
                        imageHash,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
              }}
              imageClick={handleRemovePhoto}
              showPhotoIndex
            />
          </div>
          <div className="pick-photo-grid-wrapper">
            {map(range(PICK_PHOTO_GRID_SIZE), (__, index) => (
              <div className="pick-photo-line">
                {map(range(PICK_PHOTO_GRID_SIZE), (_, ind) => {
                  const photoTakenFileName =
                    listPhotoTakens?.data?.[index * PICK_PHOTO_GRID_SIZE + ind];
                  const assetUrl = photoTakenFileName
                    ? processedImageUrl(
                        transactionId,
                        photoTakenFileName,
                        imageHash,
                      )
                    : '';
                  return (
                    !!photoTakenFileName && (
                      <button
                        type="button"
                        className={cx('pick-photo-item relative', {
                          active: !!includes(
                            pickedPhotos.map((i) => i?.fileName),
                            photoTakenFileName,
                          ),
                        })}
                        onClick={handleClickPhoto(photoTakenFileName)}
                        style={{
                          aspectRatio: `${currentLayout?.ratioX || 1} / ${
                            currentLayout?.ratioY || 1
                          }`,
                          ...((currentLayout?.ratioX || 1) >
                          (currentLayout?.ratioY || 1)
                            ? { width: `24rem` }
                            : { width: `18rem` }),
                        }}
                      >
                        <img
                          src={assetUrl}
                          alt="pick-img"
                          className="no-drag"
                        />
                        {!!includes(
                          pickedPhotos.map((i) => i?.fileName),
                          photoTakenFileName,
                        ) && (
                          <div className="pick-photo-number">
                            <Typography
                              className="font-black pick-photo-number-text"
                              variant={TYPOGRAPHY_VARIANTS.XS}
                            >
                              {findIndex(
                                pickedPhotos.map((i) => i?.fileName),
                                (o) => o === photoTakenFileName,
                              ) + 1}
                            </Typography>
                          </div>
                        )}
                        <div
                          className={`absolute right-[50%] bottom-5 translate-x-[50%]
                          } flex justify-center gap-6 items-center ${
                            includes(
                              pickedPhotos.map((i) => i?.fileName),
                              photoTakenFileName,
                            )
                              ? 'block'
                              : 'hidden'
                          }`}
                        >
                          <div className="w-12 h-12 pick-photo-bg-icon">
                            <Icons.Rotate
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRotate(photoTakenFileName);
                              }}
                            />
                          </div>
                          <div className="w-12 h-12 pick-photo-bg-icon">
                            <Icons.Flip
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFlip(photoTakenFileName);
                              }}
                            />
                          </div>
                        </div>
                      </button>
                    )
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <PageActions
        hidePrev
        NextButtonProps={{
          children: t('common:continue'),
          disabled: size(compact(pickedPhotos)) < quantityPicOfPerSheet,
        }}
        handleContinue={handleNext}
      />
    </>
  );
}

export default PickPhoto;
