import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { I18nNamespace } from 'constants/i18n.const';
import { SHOOTING_TYPES } from 'constants/photo.const';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch } from 'store/store-hooks';
import { photoTakenApi } from 'store/api/photo-taken.slice-api';
import { camerasApi } from 'store/api/camera.slice-api';
import { setTrackingStateAction } from 'store/features/app/trackingSlice';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useCustomizeUI } from 'hooks/useCustomizeUI';
import Typography, { TypographyColor } from 'components/typography/Typography';
import TimerText from 'components/timer-text/TimerText';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useSoundContext } from 'context/SoundContext';
import './shooting-mode.css';
import './shooting-mode.customize-ui.css';

function ShootingMode() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { shootingModeUI } = useCustomizeUI();
  const { audio } = useSoundContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { second } = usePageTimer({
    forwardFunc: () => handleRedirectSelectLayout(SHOOTING_TYPES.COUNTDOWN)(),
  });

  const handleRedirectSelectLayout = (id: number) => async () => {
    audio?.playContinueClick?.();
    dispatch(setSelfeAppStateAction({ shootingModeId: id }));
    navigate(getNextPath() || '');
  };

  // Start live view
  useEffect(() => {
    dispatch(setSelfeAppStateAction({ isPaidButNotShooting: false }));
  }, [dispatch]);

  // Start live view
  useEffect(() => {
    dispatch(setTrackingStateAction({ LastStartTimeTakePhoto: new Date() }));
    dispatch(camerasApi.util.invalidateTags([{ type: 'Camera' }]));
    dispatch(photoTakenApi.util.invalidateTags([{ type: 'PhotoTaken' }]));
  }, [dispatch]);

  return (
    <div>
      <TimerText second={second} />
      <div className="page-title-margin">
        <Typography
          variant={TYPOGRAPHY_VARIANTS.H1}
          data-text={t(`${I18nNamespace.COMMON}:shootingMode`)}
          className="page-title"
        >
          {t(`${I18nNamespace.COMMON}:shootingMode`)}
        </Typography>
      </div>
      <div className="flex items-center justify-center">
        <div className="dashed-border-select-frame select-mode-card-wrapper">
          <button
            type="button"
            className="select-mode-card"
            onClick={handleRedirectSelectLayout(SHOOTING_TYPES.COUNTDOWN)}
          >
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H2}
              className="text-center font-bold card-title"
              color={TypographyColor.SECONDARY}
              upperCase
            >
              {t('page:byCountdown')}
            </Typography>
            <div className="card-wrapper-img">
              <img src={shootingModeUI?.countdownImage} alt="example-img" />
            </div>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H2}
              className="text-center font-semibold"
              color={TypographyColor.SECONDARY}
              upperCase
            >
              {t('page:hasRecordVideo')}
            </Typography>
          </button>
        </div>
        <div
          className={
            'dashed-border-select-frame select-mode-card-wrapper select-mode-card-wrapper-white'
          }
        >
          <button
            type="button"
            className="select-mode-card"
            onClick={handleRedirectSelectLayout(SHOOTING_TYPES.BY_REMOTE)}
          >
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H2}
              className="text-center font-bold card-title"
              color={TypographyColor.SECONDARY}
              upperCase
            >
              {t('page:byRemote')}
            </Typography>
            <div className="card-wrapper-img">
              <img src={shootingModeUI?.remoteImg} alt="example-img" />
            </div>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H2}
              className="text-center font-semibold"
              color={TypographyColor.SECONDARY}
              upperCase
            >
              {t('page:noHasRecordVideo')}
            </Typography>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShootingMode;
