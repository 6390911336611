/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { FC, HTMLAttributes, useState, memo, useEffect } from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import Input from 'components/input/Input';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useApplyVoucherCodeApiActionMutation } from 'store/api/payment.slice-api';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useModal } from 'hooks/useModal';
import { Keyboard } from '../../../components/keyboard/Keyboard';
import './keyboard-drawer.css';
import './keyboard-drawer.customize-ui.css';

export type KeyboardDrawerDrawerProps = HTMLAttributes<HTMLDivElement> & {
  onOk?: (code: string, value: number) => void;
  onClose?: () => void;
  open: boolean;
};

const KeyboardDrawer: FC<KeyboardDrawerDrawerProps> = ({
  onOk,
  onClose,
  open,
  ...props
}) => {
  const { t } = useTranslation(I18nNamespace.COMMON);
  const [applyVoucherCodeApiAction] = useApplyVoucherCodeApiActionMutation();
  const { openErrorModal } = useModal();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();

  const [voucherCode, setVoucherCode] = useState<string>('');
  const [voucherError, setVoucherError] = useState('');
  const [isApplyingVoucher, setIsApplyingVoucher] = useState<boolean>(false);

  const handleOnChange = (code: string) => {
    setVoucherError?.('');
    setVoucherCode(code);
  };

  const handleSubmit = async () => {
    if (!voucherCode) return;
    setIsApplyingVoucher(true);
    setVoucherError('');
    if (!isOnline) {
      setIsApplyingVoucher(false);
      openErrorModal(t('page:cannotUseVoucherByNetworkError'));
      return;
    }
    const res: any = await applyVoucherCodeApiAction(voucherCode);
    setIsApplyingVoucher(false);
    if (res && res?.data) {
      audio?.playVoucherApplied?.();
      onOk?.(voucherCode, +res?.data?.data || 0);
      onClose?.();
    } else {
      audio?.playVoucherExpired?.();
      setVoucherError(get(res, 'error.data.message', t('common:commonError')));
    }
  };

  useEffect(() => {
    if (open) {
      setVoucherCode('');
      setVoucherError('');
      setIsApplyingVoucher(false);
    }
  }, [open]);

  return (
    <div
      className={cx('pb-keyboardDrawer-container', {
        'pb-keyboardDrawer-container-open': open,
      })}
    >
      <div className={cx('pb-keyboardDrawer-backdrop')} onClick={onClose} />
      <div
        {...props}
        className={cx('pb-keyboardDrawer', props?.className)}
        onClick={(e) => e.preventDefault()}
      >
        <Input
          className="pb-keyboardDrawer-input"
          value={voucherCode}
          placeholder={`${t('page:voucherCode')}`}
          isError={!!voucherError}
          readOnly
        />
        {!!voucherError && (
          <Typography
            className="voucher-code-error-text"
            variant={TYPOGRAPHY_VARIANTS.SMALL}
            firstCapCase
          >
            {voucherError}
          </Typography>
        )}
        <Keyboard
          value={voucherCode}
          loading={isApplyingVoucher}
          handleChange={handleOnChange}
          handleEnter={handleSubmit}
        />
      </div>
    </div>
  );
};

export default memo(KeyboardDrawer);
