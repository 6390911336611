import React, { ButtonHTMLAttributes, FC, HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import './page-actions.css';

export type PageActionsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  hidePrevNext?: boolean;
  hidePrev?: boolean;
  hideNext?: boolean;
  handleBack?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  handleContinue?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  PrevButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  NextButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};

const PageActions: FC<PageActionsProps> = ({
  hidePrevNext = false,
  hidePrev = false,
  hideNext = false,
  handleBack,
  handleContinue,
  PrevButtonProps,
  NextButtonProps,
  ...rest
}) => {
  const { t } = useTranslation(I18nNamespace.COMMON);
  return (
    <>
      {!hidePrevNext ? (
        <div
          {...rest}
          className={cx(
            'w-full flex items-center justify-center page-actions',
            rest?.className,
          )}
        >
          {!hidePrev && (
            <Button
              {...PrevButtonProps}
              color="default"
              onClick={PrevButtonProps?.onClick || handleBack}
            >
              {PrevButtonProps?.children || t('common:back')}
            </Button>
          )}
          {!hideNext && (
            <Button
              {...NextButtonProps}
              onClick={NextButtonProps?.onClick || handleContinue}
            >
              {NextButtonProps?.children || t('common:continue')}
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default PageActions;
