import moment from 'moment';

/**
 * Trả ra thời gian tính toán giữa hai mốc
 *
 * @category all Date
 * @param any
 * @param any
 * @returns Returns diff time.
 * @example
 *
 */
function diffFromDate(dTime1: Date, dTime2: Date): number {
  if (dTime1 && dTime2) {
    return Math.abs(moment(dTime1).diff(moment(dTime2), 'seconds'));
  }
  return 0;
}

export { diffFromDate };
