import { AnyAction, AsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PrintingAppStateModel } from 'models/app.model';
import {
  INIT_APPLIED_VOUCHER_CODE,
  INIT_APPLIED_VOUCHER_VALUE,
  INIT_DEPOSIT_AMOUNT,
  INIT_TRANSACTION_ID,
  PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT,
} from 'constants/photo.const';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const initialState: PrintingAppStateModel = {
  transactionId: INIT_TRANSACTION_ID,

  isPaidButNotShooting: false,
  isShouldOpenBillAcceptor: false,
  depositAmount: INIT_DEPOSIT_AMOUNT,
  appliedVoucherCode: INIT_APPLIED_VOUCHER_CODE,
  appliedVoucherValue: INIT_APPLIED_VOUCHER_VALUE,

  appLoading: false,

  LifeCycleStep: PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT,
};

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled');
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending');
}

export const printingeAppSlice = createSlice({
  name: 'printinge-app',
  initialState,
  reducers: {
    setPrintingAppStateAction: (
      state,
      action: PayloadAction<Partial<PrintingAppStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PrintingAppStateModel),
    resetPrintingAppStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state) => ({
        ...state,
        appLoading: true,
      }))
      .addMatcher(isFulfilledAction, (state) => ({
        ...state,
        appLoading: false,
      }))
      .addMatcher(isRejectedAction, (state) => ({
        ...state,
        appLoading: false,
      }));
  },
});

export const { setPrintingAppStateAction, resetPrintingAppStateAction } =
  printingeAppSlice.actions;

export default printingeAppSlice.reducer;
