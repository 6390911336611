import {
  GetRobotArmStatusRequestModel,
  GetRobotArmStatusResponseModel,
  RobotArmStateModel,
  UpdateRobotArmPositionRequestModel,
} from 'models/photo/robot-arm.model';
import { PREFIX_API } from 'constants/api.const';
import { robotArmBaseSliceApi } from './base.slice-api';

export const robotArmApi = robotArmBaseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    getRobotArmStatusApiAction: build.query<
      RobotArmStateModel['robotArmStatus'] | null,
      GetRobotArmStatusRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/status`,
      }),
      providesTags: () => [{ type: 'RobotArm' as const, id: 'LIST' }],
      extraOptions: { maxRetries: 1 },
      transformResponse: (rawResult: GetRobotArmStatusResponseModel) =>
        rawResult?.response ?? null,
    }),
    updateRobotArmPositionApiAction: build.mutation<
      RobotArmStateModel['robotArmPosition'],
      UpdateRobotArmPositionRequestModel
    >({
      query: (body) => ({
        url: `${PREFIX_API.ROBOT_ARM_API}/position`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'RobotArm', id: 'LIST' }],
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useGetRobotArmStatusApiActionQuery,
  useUpdateRobotArmPositionApiActionMutation,
} = robotArmApi;

export const {
  endpoints: { getRobotArmStatusApiAction, updateRobotArmPositionApiAction },
} = robotArmApi;
