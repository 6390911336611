import CONFIG from 'config';
import { APP_THEME } from 'assets/theme/theme';
import { CustomizeUIModel } from 'models/common.model';
import macosStartPageImage from 'assets/v2/images/mascot.png';
import pinkBackgroundImage from 'assets/v2/images/pink_background.png';
import dianaBackgroundImage from 'assets/diana/images/background.png';
import macosPrintImage from 'assets/v2/images/mascot_1.png';
import cdDemNguoc from 'assets/v2/images/clock.png';
import cdDieukhien from 'assets/v2/images/gaming_control.png';
import cdDemNguocDiana from 'assets/diana/images/clock.png';
import cdDieukhienDiana from 'assets/diana/images/gaming_control.png';

import whiteLogoImage from 'assets/v2/images/white_logo.png';
import dianaLogoImage from 'assets/diana/images/logo.png';

export const useCustomizeUI = (): CustomizeUIModel => {
  if (CONFIG.REACT_APP_THEME_KEY === APP_THEME.DIANA) {
    return {
      selfeLayoutClass: 'theme-diana',
      selfeAppBackgroundImgPath: dianaBackgroundImage,
      loadingImage: dianaLogoImage,
      startPageUI: {
        isShowMacos: false,
      },
      selectFrameUI: {},
      selectLayoutUI: {},
      paymentUI: {},
      shootingModeUI: {
        countdownImage: cdDemNguocDiana,
        remoteImg: cdDieukhienDiana,
      },
      shootingUI: {},
      pickPhotoUI: {},
      selectThemeUI: {},
      selectFilterUI: {},
      printUI: {
        isShowMacos: false,
        macosImg: '',
      },
    } as CustomizeUIModel;
  }

  return {
    selfeLayoutClass: 'theme-default',
    selfeAppBackgroundImgPath: pinkBackgroundImage,
    loadingImage: whiteLogoImage,
    startPageUI: {
      isShowMacos: true,
      macosImg: macosStartPageImage,
    },
    selectFrameUI: {},
    selectLayoutUI: {},
    paymentUI: {},
    shootingModeUI: {
      countdownImage: cdDemNguoc,
      remoteImg: cdDieukhien,
    },
    shootingUI: {},
    pickPhotoUI: {},
    selectThemeUI: {},
    selectFilterUI: {},
    printUI: {
      isShowMacos: true,
      macosImg: macosPrintImage,
    },
  };
};
