import React from 'react';
import backgroundFrame from 'assets/images/background_frame.png';
import backgroundLayout from 'assets/images/background_layout.png';
import backgroundPayment from 'assets/images/background_payment.png';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import Typography from 'components/typography/Typography';
import Popover from 'components/popover/Popover';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { increment, incrementAsync } from 'store/features/counter/counterSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import Checkbox from 'components/checkbox/Checkbox';
import { Carousel } from 'components/carousel/Carousel';
import { map } from 'lodash';
import { useModal } from 'hooks/useModal';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import { imageUrl } from 'helpers/url.helper';
import { useBoothAppContext } from 'context/BoothAppContext';
import PopoverBox from 'components/popover/PopoverBox';

function Guide() {
  const { t, i18n } = useTranslation(I18nNamespace.COMMON);
  const count = useAppSelector((state) => state.counter.value);
  const { openConfirmModal } = useModal();
  const dispatch = useAppDispatch();
  const { listLayoutPhotos } = useBoothAppContext();

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      <PhotoSheet
        data={{
          ...listLayoutPhotos?.data?.[0],
          pictures: map(listLayoutPhotos?.data?.[0]?.pictures, (item) => ({
            ...item,
            image: imageUrl('/Content/images/frame/frame_4x6.png'),
          })),
        }}
      />
      <div>
        <Button color="default" onClick={() => handleChangeLanguage('en')}>
          Eng
        </Button>{' '}
        | <Button onClick={() => handleChangeLanguage('vi')}>VN</Button>
        <br />
        Text: {t('noData')}
        <Button
          onClick={() =>
            openConfirmModal({
              content: `${t('common:doYouNeedMoreTime')}`,
              isUseTimer: true,
              onTimeUp: () => {
                // eslint-disable-next-line no-console
                console.log('123');
              },
              duration: 20000,
            })
          }
        >
          Click modal 1
        </Button>
        <Button loading>loading</Button>
        <Popover
          open
          content={'Bấm để chọn ảnh'}
          RenderContent={() => (
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Popup</Typography>
          )}
          PopoverBoxProps={{ popoverDir: 'left' }}
        />
        <PopoverBox content={'Bấm để chọn ảnh'} open />
      </div>
      <div />
      <div />
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
          Background frame
        </Typography>
        <img src={backgroundFrame} alt="" />
      </div>
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
          Background layout
        </Typography>
        <img src={backgroundLayout} alt="" />
      </div>
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
          Background payment
        </Typography>
        <img src={backgroundPayment} alt="" />
      </div>
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Typography</Typography>
        <br />
        <Typography variant={TYPOGRAPHY_VARIANTS.H1}>H1</Typography>
        <Typography variant={TYPOGRAPHY_VARIANTS.H2}>H2</Typography>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Body</Typography>
        <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>Small</Typography>
      </div>
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Button</Typography>
        <br />
        <Button onClick={() => dispatch(increment())}>Click</Button> |{' '}
        <Button onClick={() => dispatch(incrementAsync(2))}>
          Click to Add Async
        </Button>
        <br />
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
          Count: {count}
        </Typography>
      </div>
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Checkbox</Typography>
        <br />
        <Checkbox label="Nam" />
        <br />
        <Checkbox label="Nữ" checked={true} />
        <br />
        <Checkbox label="Nữ" disabled />
        <br />
      </div>
      <div />
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Input</Typography>
        <Input />
      </div>
      <div />
      <div />
      <div>
        <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>Carousel</Typography>
        <br />
        <Carousel
          slides={map([1, 2, 3, 4], () => (
            <p>123</p>
          ))}
        />
      </div>
    </div>
  );
}

export default Guide;
