import React, { FC } from 'react';
import cx from 'classnames';
import './input.css';
import './input.customize-ui.css';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  isError?: boolean;
};

const Input: FC<Props> = ({ className, ...rest }) => (
  <input
    {...rest}
    className={cx(
      'pb-input',
      {
        'pb-input-disabled': !!rest?.disabled,
        'pb-input-error': !!rest?.isError,
      },
      'text-pb-h1',
      className,
    )}
  />
);

export default Input;
