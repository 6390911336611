import { PREFIX_API } from 'constants/api.const';
import { map } from 'lodash';
import qs from 'query-string';
import {
  GetLayoutPhotoByFrameRequestModel,
  GetLayoutPhotoByFrameResponseModel,
  LayoutPhotoStateModel,
} from 'models/photo/layout.model';
import { baseSliceApi } from './base.slice-api';

export const layoutsApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    getLayoutByFrameIdApiAction: build.query<
      LayoutPhotoStateModel['listLayoutPhotos'],
      GetLayoutPhotoByFrameRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/layout/getbyframeid?${qs.stringify(payload)}`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'LayoutPhoto', id } as const)),
        { type: 'LayoutPhoto' as const, id: 'LIST' },
      ],
      transformResponse: (rawResult: GetLayoutPhotoByFrameResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const {
  useGetLayoutByFrameIdApiActionQuery,
  useLazyGetLayoutByFrameIdApiActionQuery,
} = layoutsApi;

export const {
  endpoints: { getLayoutByFrameIdApiAction },
} = layoutsApi;
