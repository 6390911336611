import qs from 'query-string';
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WEBCAM_STORAGE_KEY } from 'constants/common.const';
import { QUANTITY_DEFAULT } from 'constants/photo.const';
import { QUERY_STRING, SelfeRouteConst } from 'constants/route.const';
import { setSessionStorage } from 'helpers/common.helper';
import { isEqualVal } from 'helpers/string.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { find } from 'lodash';
import { CameraStateModel } from 'models/photo/camera.model';
import { FilterPhotoStateModel } from 'models/photo/filter.model';
import { FramePhotoStateModel } from 'models/photo/frame.model';
import {
  LayoutPhotoModel,
  LayoutPhotoStateModel,
} from 'models/photo/layout.model';
import { PhotoTakenStateModel } from 'models/photo/photo-taken.model';
import { ThemeCategoryPhotoStateModel } from 'models/photo/theme-category.model';
import {
  ThemeDetailPhotoModel,
  ThemePhotoStateModel,
} from 'models/photo/theme.model';
import { useGetBoothApiActionQuery } from 'store/api/booth.slice-api';
import {
  useListCameraApiActionQuery,
  useListCapturePositionApiActionQuery,
  useStartCameraLiveViewApiActionMutation,
  useStopCameraLiveViewApiActionMutation,
} from 'store/api/camera.slice-api';
import { useListFilterApiActionQuery } from 'store/api/filter.slice-api';
import { useListFrameApiActionQuery } from 'store/api/frame.slice-api';
import { useGetLayoutByFrameIdApiActionQuery } from 'store/api/layout.slice-api';
import {
  useCloseBillAcceptorApiActionMutation,
  useGetBillAcceptorApiActionQuery,
} from 'store/api/payment.slice-api';
import { useListPhotoTakenApiActionQuery } from 'store/api/photo-taken.slice-api';
import { useListThemeCategoryApiActionQuery } from 'store/api/theme-category.slice.api';
import {
  useGetThemeDetailApiActionQuery,
  useListThemeByCategoryApiActionQuery,
} from 'store/api/theme.slice-api';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { StickerCategoryPhotoStateModel } from '../models/photo/sticker-category.model';
import { StickerPhotoStateModel } from '../models/photo/sticker.model';
import { useListStickerCategoryApiActionQuery } from '../store/api/sticker-category.slice.api';
import { useListStickerByCategoryApiActionQuery } from '../store/api/sticker.slice-api';

export const Context = React.createContext<BoothAppContextReturnTypes>({
  listLayoutPhotos: null,
  listFramePhotos: null,
  listPhotoTakens: null,
  listThemePhotos: null,
  listCapturePosition: null,
  listStickerPhotos: null,
  listThemeCategoryPhotos: null,
  listStickerCategoryPhotos: null,
  themeDetail: null,
  listFilterPhotos: null,
  listCameras: null,
  currentLayout: null,
});

interface BoothAppContextProps {}
type BoothAppContextReturnTypes = {
  listFramePhotos: FramePhotoStateModel['listFramePhotos'] | null;
  listLayoutPhotos: LayoutPhotoStateModel['listLayoutPhotos'] | null;
  listPhotoTakens: PhotoTakenStateModel['listPhotoTakens'] | null;
  listThemePhotos: ThemePhotoStateModel['listThemePhotos'] | null;
  listCapturePosition: CameraStateModel['listCapturePositions'] | null;
  listStickerPhotos: StickerPhotoStateModel['listStickerPhotos'] | null;
  listThemeCategoryPhotos:
    | ThemeCategoryPhotoStateModel['listThemeCategoryPhotos']
    | null;
  listStickerCategoryPhotos:
    | StickerCategoryPhotoStateModel['listStickerCategoryPhotos']
    | null;
  themeDetail: ThemeDetailPhotoModel | null;
  listFilterPhotos: FilterPhotoStateModel['listFilterPhotos'] | null;
  listCameras: CameraStateModel['listCameras'] | null;
  currentLayout: LayoutPhotoModel | null;
};

export const BoothAppContext: FC<PropsWithChildren<BoothAppContextProps>> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { initLifeCycleApp, getStartNodePath, currentRoute } =
    useNewPhotoLifeCycleStep();
  const {
    frameId,
    layoutId,
    themeCategoryId,
    stickerCategoryId,
    themeId,
    transactionId,
    quantityPicOfPerSheet,
  } = useAppSelector((state) => state.selfeApp);

  useGetBoothApiActionQuery();
  useGetBillAcceptorApiActionQuery();

  const [startLiveViewApiAction] = useStartCameraLiveViewApiActionMutation();
  const [stopLiveViewApiAction] = useStopCameraLiveViewApiActionMutation();
  const [closeBillAcceptor] = useCloseBillAcceptorApiActionMutation();
  const { data: listCameras } = useListCameraApiActionQuery();
  const { data: listCapturePosition } = useListCapturePositionApiActionQuery();

  // request list frame
  const { data: listFramePhotos } = useListFrameApiActionQuery({
    pageIndex: 1,
    pageSize: 20,
  });
  // list layout
  const { data: listLayoutPhotos } = useGetLayoutByFrameIdApiActionQuery(
    { frameId, numberOfPicture: quantityPicOfPerSheet || QUANTITY_DEFAULT },
    { skip: !frameId },
  );
  // photo taken
  const { data: listPhotoTakens, error: errorListTaken } =
    useListPhotoTakenApiActionQuery(
      { transactionId },
      { skip: !transactionId },
    );

  // Theme category & theme
  const { data: listThemeCategoryPhotos } = useListThemeCategoryApiActionQuery({
    pageIndex: 1,
    pageSize: 9999,
  });

  // Sticker category & theme
  const { data: listStickerCategoryPhotos } =
    useListStickerCategoryApiActionQuery({
      pageIndex: 1,
      pageSize: 9999,
    });

  const { data: listThemePhotos } = useListThemeByCategoryApiActionQuery(
    { themeCategoryId, layoutId, pageIndex: 1, pageSize: 9999 },
    { skip: !themeCategoryId || !layoutId },
  );
  const { data: listStickerPhotos } = useListStickerByCategoryApiActionQuery(
    {
      stickerCategoryId,
      pageIndex: 1,
      pageSize: 9999,
    },
    { skip: !stickerCategoryId },
  );
  const { data: themeDetail } = useGetThemeDetailApiActionQuery(
    { layoutId, themeId },
    { skip: !layoutId || !themeId },
  );

  // Filter
  const { data: listFilterPhotos } = useListFilterApiActionQuery(null, {
    skip: !transactionId,
  });

  // Camera
  const cameraId = useMemo(() => listCameras?.data?.[0]?.ID, [listCameras]);
  const isCameraLiveViewOn = useMemo(
    () => listCameras?.data?.[0]?.IsLiveViewOn,
    [listCameras],
  );

  const isHasErrorListTaken = useMemo(() => !!errorListTaken, [errorListTaken]);

  // redirect khi chưa chọn frameId
  useEffect(() => {
    if (
      !transactionId &&
      getStartNodePath() !== currentRoute?.route &&
      pathname !== SelfeRouteConst.GUIDE &&
      pathname !== SelfeRouteConst.GUIDE_API
    ) {
      navigate(getStartNodePath() || '');
    }
  }, [
    dispatch,
    transactionId,
    pathname,
    getStartNodePath,
    navigate,
    currentRoute,
    listCameras,
  ]);

  // close bill acceptor session
  useEffect(() => {
    if (!currentRoute?.isPageUsePayment) {
      closeBillAcceptor();
    }
  }, [closeBillAcceptor, currentRoute?.isPageUsePayment]);

  // Bật LiveView camera
  useEffect(() => {
    if (cameraId && !isCameraLiveViewOn && currentRoute?.isOpenCameraLiveView) {
      startLiveViewApiAction();
    }
  }, [
    cameraId,
    isCameraLiveViewOn,
    startLiveViewApiAction,
    currentRoute?.isOpenCameraLiveView,
  ]);

  // Tắt LiveView camera
  useEffect(() => {
    if (currentRoute?.isStopCameraLiveView) {
      stopLiveViewApiAction();
    }
  }, [stopLiveViewApiAction, currentRoute?.isStopCameraLiveView]);

  useEffect(() => {
    initLifeCycleApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check use webcam
  useEffect(() => {
    if (qs.parseUrl(window?.location?.href)?.query?.[QUERY_STRING.CAM_DEVICE]) {
      setSessionStorage(WEBCAM_STORAGE_KEY, 1);
    }
  }, []);

  return (
    <Context.Provider
      value={useMemo(
        () => ({
          listLayoutPhotos: listLayoutPhotos ?? null,
          listFramePhotos: listFramePhotos ?? null,
          listThemePhotos: listThemePhotos ?? null,
          listStickerPhotos: listStickerPhotos ?? null,
          listCapturePosition: listCapturePosition ?? null,
          themeDetail: themeDetail ?? null,
          listThemeCategoryPhotos: listThemeCategoryPhotos ?? null,
          listStickerCategoryPhotos: listStickerCategoryPhotos ?? null,
          listFilterPhotos: listFilterPhotos ?? null,
          listCameras: listCameras ?? null,
          listPhotoTakens:
            isHasErrorListTaken || !listPhotoTakens ? null : listPhotoTakens,
          currentLayout:
            find(listLayoutPhotos?.data, (o) => isEqualVal(o?.id, layoutId)) ||
            null,
        }),
        [
          listLayoutPhotos,
          listFramePhotos,
          isHasErrorListTaken,
          listPhotoTakens,
          listThemePhotos,
          listCapturePosition,
          listStickerPhotos,
          listThemeCategoryPhotos,
          listStickerCategoryPhotos,
          themeDetail,
          listFilterPhotos,
          listCameras,
          layoutId,
        ],
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const useBoothAppContext = () => useContext(Context);
