import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Background, { BackgroundTypes } from 'components/background/Background';
import { I18nNamespace } from 'constants/i18n.const';
import { MODULE_ID } from 'constants/photo.const';
import { useTranslation } from 'hooks/useTranslation';
import {
  PrintingPhotoRouteConst,
  SelfeRouteConst,
} from 'constants/route.const';
import { MODULE_ID_KEY } from 'constants/common.const';
import { isEqualVal } from 'helpers/string.helper';
import './module-master.css';

function ModuleMaster() {
  const navigate = useNavigate();
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);

  // handle select frame
  const handleRedirectSelectLayout =
    (moduleId: number | undefined) => async () => {
      if (moduleId) {
        localStorage.setItem(MODULE_ID_KEY, `${moduleId || ''}`);
        if (isEqualVal(moduleId, MODULE_ID.SELFE_BOOTH)) {
          navigate(SelfeRouteConst.START_PAGE);
        } else if (isEqualVal(moduleId, MODULE_ID.PRINTING_BOOTH)) {
          navigate(PrintingPhotoRouteConst.START_PAGE);
        }
      }
    };

  return (
    <div className="module-master">
      <Background type={BackgroundTypes.LAYOUT} />
      <Typography
        variant={TYPOGRAPHY_VARIANTS.H1}
        className="uppercase text-center font-black page-title"
      >
        {T(`${I18nNamespace.COMMON}:selectModule`)}
      </Typography>
      <div className="flex items-center justify-center module-master-wrapper">
        <button
          type="button"
          className={cx('p-8 module-card')}
          onClick={handleRedirectSelectLayout(MODULE_ID.SELFE_BOOTH)}
        >
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="mb-16 text-center font-semibold"
          >
            {T(`${I18nNamespace.COMMON}:selfePhoto`)}
          </Typography>
        </button>
        <button
          type="button"
          className={cx('p-8 module-card')}
          onClick={handleRedirectSelectLayout(MODULE_ID.SELFE_BOOTH)}
        >
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="mb-16 text-center font-semibold"
          >
            {T(`${I18nNamespace.COMMON}:selfePhoto`)}
          </Typography>
        </button>
        <button
          type="button"
          className={cx('p-8 module-card')}
          onClick={handleRedirectSelectLayout(MODULE_ID.PRINTING_BOOTH)}
        >
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="mb-16 text-center font-semibold"
          >
            {T(`${I18nNamespace.COMMON}:printingPhoto`)}
          </Typography>
        </button>
        <button
          type="button"
          className={cx('p-8 module-card')}
          onClick={handleRedirectSelectLayout(MODULE_ID.PRINTING_BOOTH)}
        >
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H2}
            className="mb-16 text-center font-semibold"
          >
            {T(`${I18nNamespace.COMMON}:printingPhoto`)}
          </Typography>
        </button>
      </div>
    </div>
  );
}

export default ModuleMaster;
