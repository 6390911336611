import React, { FC, HtmlHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { useCustomizeUI } from 'hooks/useCustomizeUI';
import './loader.css';
import './loader.customize-ui.css';

export type LoaderProps = HtmlHTMLAttributes<HTMLDivElement> & {
  loading?: boolean;
  spin?: ReactElement | string;
  outerSpinProps?: HtmlHTMLAttributes<HTMLDivElement>;
};

const Loader: FC<LoaderProps> = ({
  children,
  loading = false,
  spin = null,
  outerSpinProps,
  ...props
}) => {
  const { loadingImage } = useCustomizeUI();

  return (
    <div
      {...props}
      className={cx('pb-loader', props?.className, {
        loading,
      })}
    >
      {children}
      {loading && (
        <div
          {...outerSpinProps}
          className={cx('pb-loader-icon-wrapper', outerSpinProps?.className)}
        >
          {spin || (
            <img
              src={loadingImage}
              className="pb-loader-img animate-scale-pulse mx-auto relative"
              alt="logo fun"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Loader;
