/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cx from 'classnames';
import { map, size } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { QUANTITY_DEFAULT, QUANTITY_TYPES } from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { isEqualVal } from 'helpers/string.helper';
import { formatMoneyVN } from 'helpers/math.helper';
import { resourceUrl } from 'helpers/url.helper';
import { useBoothAppContext } from 'context/BoothAppContext';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Button from 'components/button/Button';
import TimerText from 'components/timer-text/TimerText';
import './select-layout.css';
import './select-layout.customize-ui.css';

function SelectLayout() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { listLayoutPhotos } = useBoothAppContext();
  const { quantityPicOfPerSheet = QUANTITY_DEFAULT } = useAppSelector(
    (state) => state.selfeApp,
  );

  const { second } = usePageTimer({
    forwardFunc: () => handleSelectLayout(listLayoutPhotos?.data?.[0]?.id)(),
  });

  const handleSelectQuantity = (amount: number) => () => {
    audio?.playClickToSelect?.();
    dispatch(setSelfeAppStateAction({ quantityPicOfPerSheet: amount }));
  };

  const handleSelectLayout = (id: number | undefined) => async () => {
    if (id) {
      await dispatch(setSelfeAppStateAction({ layoutId: id }));
      audio?.playClickToSelect?.();
      navigate(getNextPath() || '');
    }
  };

  return (
    <div>
      <TimerText second={second} />
      <div className="page-title-margin">
        <Typography
          variant={TYPOGRAPHY_VARIANTS.H1}
          data-text={t(`${I18nNamespace.COMMON}:selectLayout`)}
          className="page-title"
        >
          {t(`${I18nNamespace.COMMON}:selectLayout`)}
        </Typography>
      </div>
      <div className="text-center quantity-button-group">
        {map(QUANTITY_TYPES, (quantity) => {
          const isActive = !!isEqualVal(
            quantityPicOfPerSheet || QUANTITY_DEFAULT,
            quantity,
          );
          return (
            <Button
              key={quantity}
              color={isActive ? 'primary' : 'transparent'}
              data-label={`${formatMoneyVN(
                listLayoutPhotos?.data?.[0]?.prices?.[0]?.price || 0,
              )} đ`}
              onClick={handleSelectQuantity(quantity)}
              className={cx('quantity-button', {
                'quantity-button-active': isActive,
              })}
              noBorder={!isActive}
            >
              {quantity} {t('common:picture')}
            </Button>
          );
        })}
      </div>
      <div className="flex items-center justify-center example-img-wrapper">
        {size(listLayoutPhotos?.data) ? (
          map(listLayoutPhotos?.data, (item) => (
            <img
              className="example-img transition-img"
              key={item?.id}
              src={resourceUrl(item?.imageTemplate)}
              onClick={handleSelectLayout(item?.id)}
              alt="example-img"
            />
          ))
        ) : (
          <Typography
            variant={TYPOGRAPHY_VARIANTS.BODY}
            className="text-center layout-title"
          >
            {t('common:noData')}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default SelectLayout;
