import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { map, range, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { usePageTimer } from 'hooks/usePageTimer';
import { UseCarouselTypes } from 'hooks/useCarousel';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import {
  INIT_LIST_STICKER,
  INIT_THEME_CATEGORY_ID,
  INIT_THEME_DETAIL_ID,
  INIT_THEME_ID,
  NUMBER_OF_THEME_CATEGORY_PER_PAGE,
  NUMBER_OF_THEME_PER_PAGE,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { Carousel } from 'components/carousel/Carousel';
import TimerText from 'components/timer-text/TimerText';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import Button from 'components/button/Button';
import PageActions from 'components/page-actions/PageActions';
import { processedImageUrl, resourceUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';
import './select-theme.css';
import './select-theme.customize-ui.css';

function SelectTheme() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const {
    listThemeCategoryPhotos,
    listThemePhotos,
    themeDetail,
    currentLayout,
  } = useBoothAppContext();
  const { themeCategoryId, themeId, transactionId, pickedPhotos } =
    useAppSelector((state) => state.selfeApp);
  const { second } = usePageTimer();

  const [themeCarosel, setThemeCarousel] = useState<UseCarouselTypes | null>(
    null,
  );

  const handleSelectCategory = (category: number | undefined) => () => {
    if (category) {
      audio?.playClickToSelect?.();
      themeCarosel?.setActive?.(0);
      dispatch(setSelfeAppStateAction({ themeCategoryId: category }));
    }
  };

  const handleSelectTheme = (id: number) => () => {
    audio?.playClickToSelect?.();
    dispatch(setSelfeAppStateAction({ themeId: id }));
  };

  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  const handleNext = async () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  useEffect(() => {
    if (themeDetail) {
      dispatch(
        setSelfeAppStateAction({
          themeDetailId: themeDetail?.id || INIT_THEME_DETAIL_ID,
        }),
      );
    }
  }, [dispatch, themeDetail]);

  useEffect(() => {
    dispatch(
      setSelfeAppStateAction({
        themeId: listThemePhotos?.data?.[0]?.id || INIT_THEME_ID,
      }),
    );
  }, [dispatch, listThemePhotos]);

  useEffect(() => {
    dispatch(
      setSelfeAppStateAction({
        listSelectedSticker: INIT_LIST_STICKER,
      }),
    );
  }, [dispatch, listThemePhotos]);

  useEffect(() => {
    !themeCategoryId &&
      dispatch(
        setSelfeAppStateAction({
          themeCategoryId:
            listThemeCategoryPhotos?.data?.[0]?.id || INIT_THEME_CATEGORY_ID,
        }),
      );
  }, [dispatch, listThemeCategoryPhotos, themeCategoryId]);

  return (
    <>
      <div className="theme-page">
        <TimerText second={second} />
        <div className="page-title-margin theme-title">
          <Typography
            variant={TYPOGRAPHY_VARIANTS.H1}
            data-text={t(`${I18nNamespace.COMMON}:selectTheme`)}
            className="page-title"
          >
            {t(`${I18nNamespace.COMMON}:selectTheme`)}
          </Typography>
        </div>
        <div className="text-center category-button-group">
          <Carousel
            hiddenDot
            hiddenBtn={false}
            slides={map(
              range(
                1,
                Math.ceil(
                  (listThemeCategoryPhotos?.totalRecord || 0) /
                    NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                ) + 1,
              ),
              (_, ind) => (
                <div className="button-group">
                  {map(
                    slice(
                      listThemeCategoryPhotos?.data,
                      ind * NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                      (ind + 1) * NUMBER_OF_THEME_CATEGORY_PER_PAGE,
                    ),
                    (category) => {
                      const isCateActive = isEqualVal(
                        themeCategoryId || INIT_THEME_CATEGORY_ID,
                        category?.id,
                      );
                      return (
                        <Button
                          key={category?.id}
                          color={isCateActive ? 'primary' : 'transparent'}
                          onClick={handleSelectCategory(category?.id)}
                          className={cx('category-button', {
                            'category-button-active': isCateActive,
                          })}
                        >
                          {category?.name}
                        </Button>
                      );
                    },
                  )}
                </div>
              ),
            )}
          />
        </div>
        <div className="flex items-center justify-center theme-wrapper">
          <div className="theme-image-wrapper">
            <PhotoSheet
              className="theme-image-wrapper-example-img"
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        pickedPhotos?.[ind]?.fileName,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
                image: themeDetail?.image || currentLayout?.image,
              }}
            />
          </div>
          <div className="slide-wrapper">
            <Carousel
              setCarousel={setThemeCarousel}
              className="slide-carousel"
              slides={map(
                range(
                  1,
                  Math.ceil(
                    (listThemePhotos?.totalRecord || 0) /
                      NUMBER_OF_THEME_PER_PAGE,
                  ) + 1,
                ),
                (_, ind) => (
                  <div className="theme-group">
                    {map(
                      slice(
                        listThemePhotos?.data,
                        ind * NUMBER_OF_THEME_PER_PAGE,
                        (ind + 1) * NUMBER_OF_THEME_PER_PAGE,
                      ),
                      (item) => (
                        <button
                          key={item?.id}
                          type="button"
                          className={cx('theme-item', {
                            active: isEqualVal(item?.id, themeId),
                          })}
                          onClick={handleSelectTheme(item?.id)}
                        >
                          <img src={resourceUrl(item?.image)} alt="theme-img" />
                        </button>
                      ),
                    )}
                  </div>
                ),
              )}
            />
          </div>
        </div>
      </div>
      <PageActions
        handleBack={handleBack}
        handleContinue={handleNext}
        NextButtonProps={{ disabled: !themeId }}
      />
    </>
  );
}

export default SelectTheme;
