import CONFIG from 'config';
import qs from 'query-string';
import { CAMERA_DEVICE_API, PREFIX_API } from 'constants/api.const';
import { PROCESSED_URL_PATH } from 'constants/file.const';
import { QUERY_STRING } from 'constants/route.const';
import { WEBCAM_STORAGE_KEY } from 'constants/common.const';
import { getSessionStorage } from './common.helper';

/**
 * So chuỗi với số
 *
 * @category string, number
 * @param any types to compare
 * @param any types to compare
 * @returns Returns boolean of compare.
 * @example
 *
 * isEqualVal(1, "1") => true
 * isEqualVal(0, "0") => true
 * isEqualVal(0, "") => false
 * isEqualVal(0, "") => false
 * isEqualVal(1, "abc") => false
 * isEqualVal("abc", "abc") => true
 *
 */
function stringifyUrl(obj: any, prefix: string = ''): string {
  const str = [];
  let p;
  for (p in obj) {
    if (obj[p]) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? stringifyUrl(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      );
    }
  }
  return str.join('&');
}

function aSlash(path: string = '/'): string {
  return path?.[0] === '/' ? path : `/${path}`;
}

/**
 * Url file ảnh asset theme, layout, ...
 *
 * @category string
 * @param string? types
 * @returns string
 *
 */
function imageUrl(path: string = '/'): string {
  return `${CONFIG.REACT_APP_PUBLIC_URL}${aSlash(path)}`;
}

/**
 * Url file ảnh asset theme, layout, ...
 *
 * @category string
 * @param string? types
 * @returns string
 *
 */
function resourceUrl(path: string = '/'): string {
  return `${CONFIG.REACT_APP_PUBLIC_URL}/api/file/image/resource${aSlash(
    path,
  )}`;
}

/**
 * Url file ảnh được chụp với từng transaction
 *
 * @category all string
 * @returns string.
 */
function processedImageUrl(
  transactionId: string,
  processedImgFileName: string = '/',
  imageHash: number | string = '',
): string {
  return imageUrl(
    `${PROCESSED_URL_PATH}/${transactionId}/${processedImgFileName}?${imageHash}`,
  );
}

/**
 * Url live view của camera chụp ảnh
 *
 * @category all string
 * @returns string.
 */
export function liveViewUrl(
  ratio: number,
  hash: number | string | undefined = '',
): string {
  return imageUrl(
    `/${PREFIX_API.API}/${getCamDeviceApi()}/liveview?ratio=${
      ratio || 1
    }&hash=${hash}`,
  );
}

function getCamDeviceApi(): string {
  const isUseWebCam =
    !!qs.parseUrl(window?.location?.href)?.query?.[QUERY_STRING.CAM_DEVICE] ||
    !!getSessionStorage(WEBCAM_STORAGE_KEY);
  return isUseWebCam ? CAMERA_DEVICE_API.WEBCAM : CAMERA_DEVICE_API.CANON;
}

export {
  aSlash,
  stringifyUrl,
  imageUrl,
  resourceUrl,
  processedImageUrl,
  getCamDeviceApi,
};
