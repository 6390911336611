export enum LOGGING_LEVEL {
  VERBOSE = 'Verbose',
  DEBUG = 'Debug',
  INFORMATION = 'Information',
  WARNING = 'Warning',
  ERROR = 'Error',
  FATAL = 'Fatal',
}

export const LOG_STATE_EXCEPT = [
  'LifeCycleStep',
  'modalConfirmState',
  'appLoading',
];

export const LOG_PROPERTIES = {
  Application: 'fe-booth',
  BoothId: 'NB000001',
  user: 'Liam',
};
