/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ButtonHTMLAttributes,
  FC,
  HtmlHTMLAttributes,
  ReactElement,
  useEffect,
} from 'react';
import cx from 'classnames';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import './popup.css';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useTimer } from 'hooks/useTimer';
import { FRAME_TIME_DURATION } from 'constants/photo.const';

export type PopupProps = HtmlHTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  content?: ReactElement | string | any;
  hideClose?: boolean;
  hidePrevNext?: boolean;
  hidePrev?: boolean;
  hideNext?: boolean;
  RenderContent?: () => ReactElement | string;
  onClose?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  onOk?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  PopupBoxProps?: HtmlHTMLAttributes<HTMLDivElement>;
  PrevButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  NextButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;

  isUseTimer?: boolean;
  duration?: number;
  onTimeUp?: () => void;
};

const Popup: FC<PopupProps> = ({
  open = false,
  content = '',
  hideClose = false,
  hidePrevNext = false,
  hidePrev = false,
  hideNext = false,
  RenderContent = null,
  onClose,
  onOk,
  PopupBoxProps,
  PrevButtonProps,
  NextButtonProps,

  isUseTimer = false,
  onTimeUp,
  duration = FRAME_TIME_DURATION,

  ...rest
}) => {
  const { t } = useTranslation(I18nNamespace.COMMON);

  const { second, resetTime } = useTimer({
    totalMilliseconds: duration,
    isEnabled: isUseTimer,
    timeUpFunc: () => onTimeUp?.(),
  });

  useEffect(() => {
    if (isUseTimer) {
      resetTime();
    }
  }, [isUseTimer, resetTime]);

  return open ? (
    <div {...rest} className={cx('pb-popup', rest?.className)}>
      <div
        {...PopupBoxProps}
        className={cx('pb-popup-box', PopupBoxProps?.className)}
      >
        {!hideClose && (
          <span className="pb-popup-close-icon font-Nunito" onClick={onClose}>
            x
          </span>
        )}
        <div className="pb-popup-content">
          {RenderContent ? (
            RenderContent()
          ) : (
            <Typography
              color={TypographyColor.SECONDARY}
              variant={TYPOGRAPHY_VARIANTS.BODY}
              firstCapCase
            >
              {content}
            </Typography>
          )}
        </div>
        {!hidePrevNext && (
          <div
            className={cx('flex items-center justify-center pb-popup-actions')}
          >
            {!hidePrev && (
              <Button
                {...PrevButtonProps}
                color="default"
                onClick={PrevButtonProps?.onClick || onClose}
              >
                {PrevButtonProps?.children || t('common:back')}{' '}
                {isUseTimer && `(${second})`}
              </Button>
            )}
            {!hideNext && (
              <Button
                {...NextButtonProps}
                onClick={NextButtonProps?.onClick || onOk}
              >
                {NextButtonProps?.children || t('common:continue')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Popup;
