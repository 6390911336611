import { attempt, isError } from 'lodash';

/**
 * Trả ra giá trị mặc định nếu param1 (0, null, undefined, false hoặc "")
 *
 * @category all types
 * @param any
 * @param any
 * @returns Returns default value any.
 * @example
 *
 */
function dv(val: any, dVal: any = null) {
  return val || dVal;
}

/**
 * same JSON parse
 */
export function jsonParse(str: string, defaultValue = {}) {
  const result = attempt(JSON.parse.bind(null, str));
  if (isError(result) || typeof result != 'object') {
    return defaultValue;
  }

  return result;
}

export function getLocalStorage(keyName: string): string {
  if (!window.localStorage) return '';

  return window.localStorage.getItem(keyName) || '';
}

export function setLocalStorage(keyName: string, data: any | number | string) {
  if (!window.localStorage) return;

  if (typeof data === 'string' || typeof data === 'number') {
    window.localStorage.setItem(keyName, `${data}`);
    return;
  }

  window.localStorage.setItem(keyName, JSON.stringify(data));
}

export function getSessionStorage(keyName: string): string {
  if (!window.sessionStorage) return '';

  return window.sessionStorage.getItem(keyName) || '';
}

export function setSessionStorage(
  keyName: string,
  data: any | number | string,
) {
  if (!window.sessionStorage) return;

  if (typeof data === 'string' || typeof data === 'number') {
    window.sessionStorage.setItem(keyName, `${data}`);
    return;
  }

  window.sessionStorage.setItem(keyName, JSON.stringify(data));
}

export { dv };
