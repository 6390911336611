import { PREFIX_API } from 'constants/api.const';
import qs from 'query-string';
import {
  CloseBillAcceptorSessionRequestModel,
  GetBillAcceptorSessionRequestModel,
  GetBillAcceptorSessionResponseModel,
  OpenBillAcceptorSessionRequestModel,
  ResetBillAcceptorSessionRequestModel,
} from 'models/photo/bill-acceptor.model';
import { baseSliceApi } from './base.slice-api';

export const paymentApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    openBillAcceptorApiAction: build.mutation<
      boolean,
      OpenBillAcceptorSessionRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/billacceptor/openconnection?${qs.stringify(
          payload,
        )}`,
        method: 'POST',
      }),
      extraOptions: { maxRetries: 0 },
    }),
    getBillAcceptorApiAction: build.query<
      boolean,
      GetBillAcceptorSessionRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/billacceptor/getstatus`,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: (
        rawResult: GetBillAcceptorSessionResponseModel,
        meta,
      ) => !!rawResult?.response,
      providesTags: () => [{ type: 'BillAcceptor' as const, id: 'LIST' }],
    }),
    resetBillAcceptorApiAction: build.mutation<
      boolean,
      ResetBillAcceptorSessionRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/billacceptor/reset`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'BillAcceptor', id: 'LIST' }],
      extraOptions: { maxRetries: 0 },
    }),
    closeBillAcceptorApiAction: build.mutation<
      boolean,
      CloseBillAcceptorSessionRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/billacceptor/closeconnection`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'BillAcceptor', id: 'LIST' }],
      extraOptions: { maxRetries: 0 },
    }),
    applyVoucherCodeApiAction: build.mutation<boolean, string>({
      query: (code) => ({
        url: `${PREFIX_API.API}/vouchers/${code}/apply`,
        method: 'POST',
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useLazyGetBillAcceptorApiActionQuery,
  useGetBillAcceptorApiActionQuery,
  useOpenBillAcceptorApiActionMutation,
  useResetBillAcceptorApiActionMutation,
  useCloseBillAcceptorApiActionMutation,
  useApplyVoucherCodeApiActionMutation,
} = paymentApi;

export const {
  endpoints: {
    getBillAcceptorApiAction,
    openBillAcceptorApiAction,
    resetBillAcceptorApiAction,
    closeBillAcceptorApiAction,
    applyVoucherCodeApiAction,
  },
} = paymentApi;
